<template>
  <div>
    <template v-if="campaign && !campaign.published_at" class="mb-3">
      <!--Mobile buttons-->
      <div v-if="$vuetify.breakpoint.xsOnly">
        <v-btn color="error" outlined block @click="confirmDeleteDialog = true">
          Delete
        </v-btn>
        <v-btn
          color="primary"
          block
          class="mt-3"
          @click="showPreviewDialog = true"
        >
          Preview
        </v-btn>
        <v-btn
          color="success"
          block
          class="mt-3"
          data-intercom-target="create_campaign.global.btn.publish"
          :disabled="!canPublish"
          @click="showPublishDialog = true"
        >
          Publish
        </v-btn>
      </div>
      <v-container class="p-0 mb-3" fluid grid-list-xl>
        <v-layout wrap>
          <v-flex lg5 md6 sm6 xs12>
            <div>
              <v-img
                width="30"
                :src="'/img/flags/' + campaign.country.flag"
                :alt="campaign.country.name"
                class="rounded-sm"
              />
            </div>
            
            <v-text-field
              v-model="campaign.name"
              label="Campaign name"
              hint="Min. 10 characters"
              persistent-hint
              :counter="64"
              v-validate="'min:3|max:64'"
              data-vv-name="Campaign name"
              :error-messages="errors.collect('Campaign name')"
              data-vv-validate-on="blur"
              class="mb-3"
              data-intercom-target="create_campaign.global.input.name"
            >
              <template v-if="$vuetify.breakpoint.smAndUp" slot="append">
                <emoji-picker
                  close-on-content-click
                  @emoji="campaign.name += $event"
                ></emoji-picker>
              </template>
            </v-text-field>

            <div style="height: 30px;">
              <small v-if="saving">
                Saving
                <v-progress-circular
                  :size="14"
                  :width="1"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </small>
              <small v-else-if="saveFailed" class="error--text">
                Automatic saving failed
                <v-btn small color="success" @click="saveCampaign()">Save</v-btn>
              </small>
              <small v-else class="font-italic">
                Last saved for {{ updatedAtText }}
              </small>
            </div>
          </v-flex>
          <!--Non mobile buttons-->
          <v-flex v-if="$vuetify.breakpoint.smAndUp" lg7 md6 sm6 xs12>
            <v-container fluid fill-height>
              <v-layout align-center justify-end>
                <v-btn
                  color="error"
                  outlined
                  class="mr-2"
                  @click="confirmDeleteDialog = true"
                >
                  Delete
                </v-btn>
                <v-btn
                  :color="canPublish ? 'success' : 'primary'"
                  @click="openPreviewModal()"
                >
                  <span v-if="canPublish">Publish</span>
                  <span v-else>Preview</span>
                </v-btn>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
      <create-campaign-stepper
        v-model="campaign"
        ref="createCampaignStepper"
        @completed="campaignStepsCompleted = $event"
        @publish="openPreviewModal()"
      ></create-campaign-stepper>
      <!--Confirm delete dialog-->
      <confirm-dialog
        v-model="confirmDeleteDialog"
        title="Delete draft"
        color="error"
        :loading="saving"
        @confirm="deleteCampaign()"
      ></confirm-dialog>
      <campaign-preview-dialog
        v-if="campaign"
        v-model="showPreviewDialog"
        :campaign="campaign"
      ></campaign-preview-dialog>
    </template>
    <div v-else>
      Campaign draft not found
    </div>
  </div>
</template>
<script>
import CreateCampaignStepper from "../steppers/create-campaign-stepper/CreateCampaignStepper";
import { mapActions, mapGetters } from "vuex";
import humanize_datetime from "@/helpers/filters/humanize_datetime";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import CampaignPreviewDialog from "../dialogs/CampaignPreviewDialog";
import EmojiPicker from "../pickers/EmojiPicker";
import _ from "lodash";

export default {
  components: {
    EmojiPicker,
    CampaignPreviewDialog,
    ConfirmDialog,
    CreateCampaignStepper,
  },
  filters: {
    humanize_datetime,
  },
  data: () => ({
    campaign: null,
    updatedAt: null,
    updatedAtText: null,
    updatedAtInterval: null,
    saving: false,
    saveFailed: false,
    confirmDeleteDialog: false,
    showPreviewDialog: false,
    beforePublish: false,
    campaignStepsCompleted: false,
  }),
  computed: {
    ...mapGetters("core/auth", ["isAgency", "isBusiness", "isFullyManaged"]),

    todayMoreThanStartDate() {
      if (!this.campaign.starting_at) {
        return true;
      }
      const start = this.$moment(this.campaign.starting_at);
      const today = this.$moment();
      const daysAfterStart = Math.ceil(
        today.diff(start) / (1000 * 60 * 60 * 24)
      );
      if (daysAfterStart > 1) {
        return true;
      } else {
        return false;
      }
    },
    canPublish() {
      // Fully Managed can't publish new campaigns
      if (this.isBusiness && this.isFullyManaged) {
        return false;
      }

      return (
        this.campaign &&
        this.campaign.name &&
        this.campaign.name.length >= 10 &&
        this.campaignStepsCompleted &&
        !this.todayMoreThanStartDate
      );
    },
  },
  watch: {
    campaign: {
      deep: true,
      handler: _.debounce(function() {
        this.saveCampaign();
      }, 500),
    },
  },
  methods: {
    ...mapActions("loading", ["setPageLoading"]),
    ...mapActions("core/campaigns", [
      "updateCampaign",
      "loadCampaign",
      "removeCampaign",
    ]),
    setUpdatedAt(updatedAt) {
      this.updatedAt = this.$moment.utc(updatedAt).subtract(5, "seconds");
      this.setUpdatedAtText();
    },
    setUpdatedAtText() {
      this.updatedAtText = this.$options.filters.humanize_datetime(
        this.updatedAt
      );
    },
    openPreviewModal() {
      if (this.canPublish) {
        this.beforePublish = true;
      } else {
        this.beforePublish = false;
      }
      this.showPreviewDialog = true;
    },
    saveCampaign() {
      this.saving = true;
      let params = {
        uuid: this.campaign.uuid,
        name: this.campaign.name,
        hidden: this.campaign.hidden,
        utm_campaign: this.campaign.utm_campaign,
        utm_source: this.campaign.utm_source,
        utm_medium: this.campaign.utm_medium,
        description: this.campaign.description,
        recommendations_description: this.campaign.recommendations_description,
        promotion_material_description: this.campaign
          .promotion_material_description,
        product_description: this.campaign.product_description,
        allow_deep_linking: this.campaign.allow_deep_linking,
        url: this.campaign.url,
        min_followers: this.campaign.min_followers,
        starting_at: this.campaign.starting_at
          ? this.$moment(this.campaign.starting_at).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : null,
        ending_at: this.campaign.starting_at
          ? this.$moment(this.campaign.ending_at).format("YYYY-MM-DD HH:mm:ss")
          : null,
        type: this.campaign.type,
      };
      if (this.campaign.category) {
        params.channel_category_id = parseInt(this.campaign.category, 10);
      }
      for (let key in params) {
        if (params[key] || params[key] === 0 || params[key] === false) {
          // do nothing
        } else {
          delete params[key];
        }
      }
      if (this.campaign.cost_per_action_percent) {
        params.cost_per_action_percent = this.campaign.cost_per_action_percent;
        params.cost_per_action_fixed = null;
        params.cost_per_click = null;
        params.max_commission_budget = null;
      }
      if (this.campaign.cost_per_action_fixed) {
        params.cost_per_action_fixed = this.campaign.cost_per_action_fixed;
        params.cost_per_action_percent = null;
        params.cost_per_click = null;
        params.max_commission_budget = null;
      }
      if (this.campaign.cost_per_click) {
        params.cost_per_click = this.campaign.cost_per_click;
        params.max_commission_budget = this.campaign.max_commission_budget;
        params.cost_per_action_percent = null;
        params.cost_per_action_fixed = null;
      }
      this.updateCampaign(params).then(
        (updateCampaign) => {
          this.saving = false;
          this.saveFailed = false;
          this.setUpdatedAt(updateCampaign.updated_at);
        },
        (error) => {
          console.log(error);
          this.setSnackError("Save failed");
          this.saving = false;
          this.saveFailed = true;
        }
      );
    },
    deleteCampaign() {
      this.saving = true;
      let params = { uuid: this.campaign.uuid };

      this.removeCampaign(params).then(
        () => {
          this.saving = false;
          this.$router.push({
            name: this.isAgency ? "campaigns" : "campaigns.index",
          });
        },
        () => {
          this.setSnackError("Something went wrong.");
          this.saving = false;
        }
      );
    },
  },
  created() {
    this.setPageLoading(true);
    let params = { uuid: this.$route.params.id };

    this.loadCampaign(params).then(
      (campaign) => {
        this.setUpdatedAt(campaign.updated_at);
        this.campaign = campaign;
        this.setPageLoading(false);
        // Force update the text
        this.updatedAtInterval = setInterval(() => {
          this.setUpdatedAtText();
        }, 15 * 1000);
      },
      () => {
        this.setPageLoading(false);
      }
    );
  },
  destroyed() {
    if (this.updatedAtInterval !== null) {
      clearInterval(this.updatedAtInterval);
    }
  },
};
</script>

<style scoped></style>
