<template>
  <v-form>
    <v-stepper v-model="step" vertical class="elevation-0">
      <v-stepper-step
        step="1"
        :complete="stepSaleOrLeadCompleted"
        :color="stepSaleOrLeadCompleted ? 'success' : 'primary'"
        @click="step = 1"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 1">
          <div>Compensation for influencers</div>
          <small v-if="stepSaleOrLeadCompleted">
            {{ costTypeText }}
          </small>
        </div>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-container grid-list-xl fluid class="p-0">
          <v-layout wrap>
            <v-flex sm4 xs12>
              <v-card flat class="bordered fill-height">
                <div class="text-right p-2">
                  <information-dialog
                    title="Procent af ordre"
                    subtitle="Based on completed sales "
                    text="Set the percentage rate the influencer earns per order she generates "
                  >
                    <v-icon small>fal fa-question-circle</v-icon>
                  </information-dialog>
                </div>
                <v-card-text>
                  <div class="text-center">
                    <v-icon large>fal fa-percent</v-icon>
                    <div class="headline mt-2">
                      Percent of order
                    </div>
                    <v-btn
                       v-if="hasCpaPermission"
                      small
                      class="mt-3"
                      :color="isCostPerActionPercent ? 'success' : 'primary'"
                      :outlined="isCostPerActionPercent ? false : true"
                       data-intercom-target="create_campaign.btn.cpa_percent"
                      @click="
                        selectPricingType(
                          'percent',
                          true,
                          recommendPercentValues[2].value
                        )
                      "
                    >
                      {{ isCostPerActionPercent ? "Selected" : "Select" }}
                    </v-btn>
                    <v-btn
                       v-if="! hasCpaPermission"
                       small
                       class="mt-3"
                       color="primary"
                       outlined
                       target="_blank"
                    >
                      <v-icon small class="mr-1">fal fa-lock</v-icon> Selection blocked
                    </v-btn>
                    <div>
                      <small>Recommended for webshop owners</small>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex sm4 xs12>
              <v-card flat class="bordered fill-height">
                <div class="text-right p-2">
                  <information-dialog
                    title="Fixed lead price"
                    subtitle="Charged in fixed amount."
                    text="Set a fixed lead price. This is what the influencer earns per lead they generate for you."
                  >
                    <v-icon small>fal fa-question-circle</v-icon>
                  </information-dialog>
                </div>
                <v-card-text>
                  <div class="text-center">
                    <v-icon large>fal fa-user-check</v-icon>
                    <div class="headline mt-2">
                      Fixed lead price
                    </div>
                    <v-btn
                      v-if="hasCpaPermission"
                      small
                      class="mt-3"
                      :color="isCostPerActionFixed ? 'success' : 'primary'"
                      :outlined="isCostPerActionFixed ? false : true"
                      data-intercom-target="create_campaign.btn.cpa_fixed"
                      @click="selectPricingType('fixed', true, 50)"
                    >
                      {{ isCostPerActionFixed ? "Selected" : "Select" }}
                    </v-btn>
                    <v-btn
                       v-if="! hasCpaPermission"
                       small
                       class="mt-3"
                       color="primary"
                       outlined
                       target="_blank"
                    >
                      <v-icon small class="mr-1">fal fa-lock</v-icon> Selection blocked
                    </v-btn>
                    <div>
                      <small>Recommended for you, if you're operating with leads or downloads</small>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex sm4 xs12>
              <v-card flat class="bordered fill-height">
                <div class="text-right p-2">
                  <information-dialog
                    title="Per click"
                    subtitle="Charged per click"
                    text="Set a fixed click price. This is what the influencer earns per click they generate for you."
                  >
                    <v-icon small>fal fa-question-circle</v-icon>
                  </information-dialog>
                </div>
                <v-card-text>
                  <div class="text-center">
                    <v-icon large>fal fa-hand-point-up</v-icon>
                    <div class="headline mt-2">
                      Per click
                    </div>
                    <v-btn
                      small
                      class="mt-3"
                      :color="isCostPerClick ? 'success' : 'primary'"
                      :outlined="isCostPerClick ? false : true"
                      data-intercom-target="create_campaign.btn.cpc"
                      @click="selectPricingType('click', true, 2)"
                    >
                      {{ isCostPerClick ? "Selected" : "Select" }}
                    </v-btn>
                    <div>
                      <small>
                        Recommended for you without a web shop
                      </small>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>
      <v-stepper-step
        step="2"
        :complete="stepSummaryCompleted"
        :color="stepSummaryCompleted ? 'success' : 'primary'"
        @click="step = 2"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 2">
          <div>Summary</div>
          <small>
            Set amount and see example price
          </small>
        </div>
      </v-stepper-step>
      <v-stepper-content step="2" data-intercom-target="create_campaign.step2.2.cost_value">
        <v-container grid-list-xl fluid class="p-0">
          <v-layout wrap>
            <v-flex sm6 xs12>
              <v-select
                v-show="isCostPerActionPercent"
                v-model="percentSelectValue"
                label="Select parent value"
                :items="[
                  ...recommendPercentValues,
                  { text: 'Custom', value: 'custom' }
                ]"
                class="mb-2"
                @change="selectPercentValue"
                data-intercom-target="create_campaign.step2_2.select.cpa_percent"
              ></v-select>
              <v-text-field
                v-show="isCostPerActionPercent && showPercentField"
                label="Percent of order"
                v-model="tmpPricing.cost_per_action_percent"
                v-validate="{
                  rules: {
                    decimal: 2,
                    required: true,
                    min_value: 0,
                    max_value: 100
                  }
                }"
                data-vv-name="cost_per_action_percent"
                data-vv-as="Percent"
                :error-messages="errors.collect('cost_per_action_percent')"
                class="mb-4"
                type="number"
                append-icon="fal fa-percent"
                data-intercom-target="create_campaign.step2_2.input.cpa_percent"
              ></v-text-field>
              <v-text-field
                v-show="isCostPerActionFixed"
                label="Fixed lead price"
                persistent-hint
                hint="Type amount"
                v-model="tmpPricing.cost_per_action_fixed"
                v-validate="{
                  rules: { decimal: 2, required: true, min_value: 0 }
                }"
                data-vv-name="cost_per_action_fixed"
                data-vv-as="Lead price"
                :error-messages="errors.collect('cost_per_action_fixed')"
                class="mb-4"
                type="number"
                append-icon="fal fa-user-check"
              ></v-text-field>
              <a v-if="isCostPerClick" href="https://intercom.help/Make-influence-com/da/articles/6248423-hvad-er-en-klik-kampagne" target="_blank">
                Learn how to price your click campaign
              </a>
              <v-text-field
                v-show="isCostPerClick"
                label="Price per click"
                persistent-hint
                hint="Type amount - (excl. VAT and service fee)"
                v-model="tmpPricing.cost_per_click"
                v-validate="{
                  rules: { decimal: 2, required: true, min_value: 0 }
                }"
                data-vv-name="cost_per_click"
                data-vv-as="Click price"
                :error-messages="errors.collect('cost_per_click')"
                class="mb-4"
                type="number"
                append-icon="fal fa-hand-point-up"
              ></v-text-field>
              <v-text-field
                v-show="isCostPerClick"
                label="Max budget per influencer"
                persistent-hint
                hint="Type amount - (excl. VAT and service fee)"
                v-model="tmpPricing.max_commission_budget"
                v-validate="{
                  rules: { decimal: 2, required: true, min_value: 1000 }
                }"
                data-vv-name="max_commission_budget"
                data-vv-as="Max budget per influencer"
                :error-messages="errors.collect('max_commission_budget')"
                class="mb-4"
                type="number"
                append-icon="fal fa-abacus"
              ></v-text-field>
            </v-flex>
            <v-flex sm6 xs12>
              <v-card flat class="bordered">
                <v-card-text>
                  <div class="font-weight-bold">Example price</div>
                  <v-layout row wrap class="mt-4">
                    <v-flex v-show="isCostPerActionPercent" xs12>
                      <v-layout>
                        <v-flex xs6>
                          {{ value.cost_per_action_percent }}% commission from
                          order on {{ orderExampleValue }} kr.
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          {{
                            (
                              (value.cost_per_action_percent / 100) *
                              orderExampleValue
                            ).toFixed(2)
                          }}
                          kr.
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      v-if="value.cost_per_action_percent !== null"
                      xs12
                      class="pt-0 pb-0"
                    >
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex v-show="isCostPerActionFixed" xs12>
                      <v-layout>
                        <v-flex xs6>
                          Commission for a sign-up, a download or similar
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          {{ value.cost_per_action_fixed }} kr.
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      v-if="value.cost_per_action_fixed !== null"
                      xs12
                      class="pt-0 pb-0"
                    >
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex v-if="isCostPerClick" xs12>
                      <v-layout>
                        <v-flex xs6>
                          Click price
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          {{ value.cost_per_click ? (value.cost_per_click).toFixed(2) : '0.00' }} kr.
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      v-if="value.cost_per_click !== null"
                      xs12
                      class="pt-0 pb-0"
                    >
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12 class="pt-1 pb-0">
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout>
                        <v-flex xs6>
                          Commission total
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          {{ commissionTotal.toFixed(2) }} kr.
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout>
                        <v-flex xs6>
                          Service fee ({{ serviceFeePercent }}% of commission
                          total)
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          {{ serviceFeeTotal.toFixed(2) }} kr.
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout>
                        <v-flex xs6>
                          Subtotal
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          {{ subTotal.toFixed(2) }} kr.
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout>
                        <v-flex xs6>
                          VAT
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          {{ vatTotal.toFixed(2) }} kr.
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 class="pt-0 pb-0">
                      <v-divider class="black"></v-divider>
                      <v-divider class="black mt-1"></v-divider>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout>
                        <v-flex xs6 class="font-weight-bold">
                          You pay
                        </v-flex>
                        <v-flex xs6 class="text-right font-weight-bold">
                          {{ total.toFixed(2) }} kr.
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>
    </v-stepper>
    <div v-if="allCompleted" class="text-center pb-4">
      <v-btn class="mr-2" color="black" text @click="$emit('previous', true)"
        >Back</v-btn
      >
      <v-btn color="success" medium data-intercom-target="create_campaign.btn.step2_next" @click="$emit('next', true)">Continue</v-btn>
    </div>
  </v-form>
</template>
<script>
import InformationDialog from "../../../dialogs/InformationDialog";

export default {
  components: {InformationDialog },
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    step: 1,
    orderExampleValue: 100,
    serviceFeePercent: 15,
    costType: null,
    recommendPercentValues: [
      { text: "15%", value: 15 },
      { text: "20%", value: 20 },
      { text: "25%", value: 25 },
      { text: "30%", value: 30 }
    ],
    percentSelectValue: null,
    tmpPricing: {
      cost_per_action_percent: null,
      cost_per_action_fixed: null,
      cost_per_click: null,
      max_commission_budget: null
    },
    showPercentField: false
  }),
  computed: {
    hasCpaPermission() {
      return this.value.business.pixel_approved_at !== null;
    },
    isCostPerActionPercent() {
      return this.costType === "percent";
    },
    isCostPerActionFixed() {
      return this.costType === "fixed";
    },
    isCostPerClick() {
      return this.costType === "click";
    },
    costTypeText() {
      if(this.isCostPerActionPercent) {
        return "Percent of order";
      } else if(this.isCostPerActionFixed) {
        return "Fixed lead price";
      } else if(this.isCostPerClick) {
        return "Per click";
      }

      return "Choose compensation model";
    },
    stepSaleOrLeadCompleted() {
      return this.percentValue !== null;
    },
    stepSummaryCompleted() {
      if (this.isCostPerActionPercent && this.value.cost_per_action_percent !== null) {
        return true;
      } else if (this.isCostPerActionFixed && this.value.cost_per_action_fixed !== null) {
        return true;
      } else if (this.isCostPerClick && this.value.cost_per_click !== null && this.value.max_commission_budget !== null) {
        return true;
      }

      return false;
    },
    allCompleted() {
      return this.stepSaleOrLeadCompleted && this.stepSummaryCompleted;
    },
    commissionTotal() {
      let commission = 0;
      if (this.value.cost_per_action_fixed !== null) {
        commission += this.value.cost_per_action_fixed;
      }
      if (this.value.cost_per_action_percent !== null) {
        commission +=
          (this.value.cost_per_action_percent / 100) * this.orderExampleValue;
      }

      if(this.value.cost_per_click) {
        commission += this.value.cost_per_click;
      }

      return parseFloat("" + commission);
    },
    serviceFeeTotal() {
      if (this.commissionTotal === 0) {
        return 0;
      }

      return (this.serviceFeePercent / 100) * this.commissionTotal;
    },
    subTotal() {
      return this.commissionTotal + this.serviceFeeTotal;
    },
    vatTotal() {
      let vatPercent = 25;
      return (vatPercent / 100) * this.subTotal;
    },
    total() {
      return this.subTotal + this.vatTotal;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!newValue) {
          return;
        }
        if (!oldValue || newValue.id !== oldValue.id) {
          this.serviceFeePercent = this.value.business.service_fee;
          this.tmpPricing.cost_per_action_fixed = this.value.cost_per_action_fixed;
          this.tmpPricing.cost_per_action_percent = this.value.cost_per_action_percent;
          this.tmpPricing.cost_per_click = this.value.cost_per_click;
          this.tmpPricing.max_commission_budget = this.value.max_commission_budget;
        }
      }
    },
    tmpPricing: {
      deep: true,
      handler() {
        this.$validator.validateAll().then(() => {
          if (this.errors.collect("cost_per_action_fixed").length === 0) {
            this.value.cost_per_action_fixed = Number(
              this.tmpPricing.cost_per_action_fixed
            );
          } else {
            this.value.cost_per_action_fixed = null;
          }

          if (this.errors.collect("cost_per_action_percent").length === 0) {
            this.value.cost_per_action_percent = Number(
              this.tmpPricing.cost_per_action_percent
            );
          } else {
            this.value.cost_per_action_percent = null;
          }

          if (this.errors.collect("cost_per_click").length === 0) {
            this.value.cost_per_click = Number(this.tmpPricing.cost_per_click);
          } else {
            this.value.cost_per_click = null;
          }

          if (this.errors.collect("max_commission_budget").length === 0) {
            this.value.max_commission_budget = Number(
              this.tmpPricing.max_commission_budget
            );
          } else {
            this.value.max_commission_budget = null;
          }
        });
      }
    }
  },
  methods: {
    selectPricingType(costType = "percent", switchStep, value) {
      this.costType = costType;

      if (this.isCostPerActionPercent) {
        this.value.cost_per_action_percent = value ? value : null;
        this.tmpPricing.cost_per_action_percent = value ? value : null;
        this.value.cost_per_action_fixed = null;
        this.tmpPricing.cost_per_action_fixed = null;
        this.value.cost_per_click = null;
        this.tmpPricing.cost_per_click = null;
        this.value.max_commission_budget = null;
        this.tmpPricing.max_commission_budget = null;

        // if edit
        if (this.value.cost_per_action_percent) {
          const arr = this.recommendPercentValues.map(item => {
            return item.value;
          });
          if (arr.includes(value)) {
            this.percentSelectValue = value;
            this.showPercentField = false;
          } else {
            this.percentSelectValue = "custom";
            this.showPercentField = true;
          }
        }
      } else if (this.costPerActionFixed) {
        this.value.cost_per_action_fixed = value ? value : 50;
        this.tmpPricing.cost_per_action_fixed = value ? value : 50;
        this.value.cost_per_action_percent = null;
        this.tmpPricing.cost_per_action_percent = null;
        this.value.cost_per_click = null;
        this.tmpPricing.cost_per_click = null;
        this.value.max_commission_budget = null;
        this.tmpPricing.max_commission_budget = null;
      } else if (this.isCostPerClick) {
        this.value.cost_per_click = value ? value : 2;
        this.tmpPricing.cost_per_click = value ? value : 2;
        this.value.max_commission_budget = this.value.max_commission_budget || 1000;
        this.tmpPricing.max_commission_budget = this.tmpPricing.max_commission_budget || 1000;
        this.value.cost_per_action_fixed = null;
        this.tmpPricing.cost_per_action_fixed = null;
        this.value.cost_per_action_percent = null;
        this.tmpPricing.cost_per_action_percent = null;
      }

      if (switchStep) {
        this.step = 2;
      }
    },
    selectPercentValue(event) {
      if (event === "custom") {
        this.showPercentField = true;
        this.value.cost_per_action_percent = null;
        this.tmpPricing.cost_per_action_percent = null;
      } else {
        this.showPercentField = false;
        this.value.cost_per_action_percent = Number(event);
        this.tmpPricing.cost_per_action_percent = Number(event);
      }
    }
  },
  created() {
    if (this.value.cost_per_action_percent) {
      this.selectPricingType(
        "percent",
        false,
        this.value.cost_per_action_percent
      );
    }
    if (this.value.cost_per_action_fixed) {
      this.selectPricingType("fixed", false, this.value.cost_per_action_fixed);
    }
    if (this.value.cost_per_click) {
      this.selectPricingType("click", false, this.value.cost_per_click);
    }

    window.Intercom('trackEvent', 'create_campaign.step2');
  }
};
</script>
<style lang="scss" scoped>
.recommend-values-list {
  display: flex;
  .recommend-value-item {
    border-radius: 50px;
    border: 1px solid black;
  }
}
</style>
